.App {
  min-height: 100%;
}
@media (max-width: 768px){
  .App {
    padding-left: 15%;
  }
}

.App-link {
  color: #61dafb;
}

.Sidebar-Content {
  text-align: center;
  padding: "16px";
}

.page-header {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 2%;
}

.react-bs-table-container {
height:100%;
}

@media (max-width: 480px) {
  .react-bs-container-body    {
      max-height: 100%;
      overflow-y: scroll;
  }
}

@media (min-width: 480px) {
  .react-bs-container-body {
    max-height: 80vmin;
    overflow-y: scroll;
  }
}
