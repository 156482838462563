body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  min-height: 100%;
}
@media (max-width: 768px){
  .App {
    padding-left: 15%;
  }
}

.App-link {
  color: #61dafb;
}

.Sidebar-Content {
  text-align: center;
  padding: "16px";
}

.page-header {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 2%;
}

.react-bs-table-container {
height:100%;
}

@media (max-width: 480px) {
  .react-bs-container-body    {
      max-height: 100%;
      overflow-y: scroll;
  }
}

@media (min-width: 480px) {
  .react-bs-container-body {
    max-height: 80vmin;
    overflow-y: scroll;
  }
}

